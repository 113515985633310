import React from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMAGE_MODE,
  TRUCATE_STRING,
  CONTENT_TYPES as TYPES,
} from '../../../constants/contentTypes';
import { ERROR_CODE } from '../../../constants/errorCode';
import { NO_IMAGE_DEFAULT } from '../../../constants/noImage';
import useRouter from '../../../customHooks/useRouter';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import {
  getSeoTitleInSection,
  validateUrlRedirect,
} from '../../../utils/ContentUtils';
import { getContentImageByMode } from '../../../utils/imageUtils';
import { truncateString } from '../../../utils/objectUtils';
import { convertPath } from '../../../utils/pathRoutesUtils';
import {
  checkifHaveContentAccess,
  openStore,
} from '../../../utils/validationUserUtils';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import { selectCurrentContentPosition } from '../../ProfileManager/selectors';
import * as actions from './actions';
import { TITLES } from './constants';
import * as hooks from './hooks';
import * as selectors from './selectors';
// #region /////// COMPONENTS ///////
import {
  ContentOverviewFuture,
  ContentOverviewLive,
  ContentRelated,
  GenericModal,
  SchedulePopover,
} from '@tbx/experience-widgets-lib';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import Footer from '../../../components/Footer';
import IFrameComponent from '../../../components/IFrameComponent';
import SEO from '../../../components/Seo';
import SocialSharingList from '../../../components/SocialSharingList';
import useBrandInfo from '../../../customHooks/useBrandInfo';
import useClientConfigs from '../../../customHooks/useClientConfigs';
import ExtendedFooter from '../../../components/ExtendedFooter';
// #endregion

// #region /////// VARIABLES goBackFunction ///////
const redirect = {
  url: '/',
  countGoBack: 0,
  boolGoBack: false,
};
// #endregion

function ContentOverViewSection({ accessToken, storeTime }) {
  const router = useRouter();
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const contentID = router.query.contentID;
  const mainDiv = React.useRef();
  const { t, i18n } = useTranslation();
  const translationTexts = useWidgetTranslation([...TITLES, 'at']);
  const [showFooter, setShowFooter] = React.useState(false);
  const dispatch = useDispatch();
  const BRAND_INFO = useBrandInfo();
  const { showContentDescription, useExtendedFooter } = useClientConfigs();

  // const [contentRelatedId, setContentRelatedId] = React.useState(0);
  // const [contentEpisodeId, setContentEpisodeId] = React.useState(0);

  // #region /////// SELECTORS ///////
  const selectedContent = useSelector((state) =>
    selectors.selectContentWidget(state),
  );
  const selectedContentRelated = useSelector((state) =>
    selectors.selectContentRelatedWidget(state),
  );
  // const selectContentEpisodes = useSelector(state => selectors.selectContentEpisodesWidget(state));
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const networks = useSelector((state) => selectors.selectNetworks(state));
  const redirectUrl = useSelector((state) =>
    selectors.selectContentsUrlByID(state),
  );
  const currentContentPosition = useSelector((state) =>
    selectCurrentContentPosition(state),
  );
  const selectError = useSelector((state) => selectors.selectError(state));

  const appSettings = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const { mobileAppUrls } = appSettings;
  // #endregion

  // #region ///// HOOK EFFECTS /////
  hooks.useDidMount(
    accessToken,
    contentID,
    mainDiv,
    redirectUrl,
    authenticated,
  );
  const toastAdd = hooks.useToastAdd();
  hooks.useStoreTimeOnClickBack(storeTime, redirect, router, PATHS);
  // #endregion

  const truncatedDescription = truncateString(
    selectedContent?.description,
    TRUCATE_STRING.METATAGS_DESCRIPTION,
  );
  const imageUrl = getContentImageByMode(selectedContent?.images, 'THUMB');
  const contentFlights = selectedContent?.flights ?? [];
  const endDateContent = contentFlights[0]?.flights[0]?.end
    ? new Date(contentFlights[0]?.flights[0]?.end)
    : null;

  const onClickPlayerButton = (contentId) => {
    const hasRedirect = validateUrlRedirect(currentContentPosition);
    hasRedirect &&
      authenticated &&
      dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      openStore(mobileAppUrls);
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {
      if (!hasRedirect) {
        redirect.countGoBack = 0;
        router.push(convertPath(PATHS.player, contentId));
      }
    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      // const returnURL = hasRedirect ? origin + '/content/' + contentID : origin + `/content/${ currentContentPosition ? currentContentPosition?.currentContent.id : contentId}`;
      const returnURL = origin + convertPath(PATHS.live, contentId);
      router.push(PATHS.login, { returnURL });
    }
  };

  const onClickContentSelected = React.useCallback(
    async (ev, contentId) => {
      ev.preventDefault();
      setShowFooter(false);
      redirect.countGoBack = 0;
      router.push(convertPath(PATHS.live, contentId));
    },
    [router],
  );

  const goBackFunction = React.useCallback(async () => {
    if (
      (redirect.countGoBack >= 2 && redirect.boolGoBack) ||
      router.location.state?.oneBack.path === PATHS.player ||
      !router.location.state?.oneBack.path
    ) {
      redirect.countGoBack = 0;
      return router.push(redirect.url);
    }
    redirect.countGoBack = redirect.countGoBack + 1;
    return router.goBack();
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error });
  };

  const renderFooter = () => {
    if (!showFooter) {
      return <></>;
    }
    return useExtendedFooter ? <ExtendedFooter /> : <Footer />;
  };

  React.useEffect(() => {
    if (
      ERROR_CODE[selectError.code] ||
      (!!selectError.code && !!selectError.details && !!selectError.message)
    ) {
      toastAdd(selectError);
      return goBackFunction();
    }
  }, [selectError]);

  return (
    <React.Fragment>
      {selectedContent && (
        <SEO
          title={getSeoTitleInSection(
            selectedContent,
            t,
            i18n.language,
            '',
            BRAND_INFO,
          )}
          contentTitle={getSeoTitleInSection(
            selectedContent,
            t,
            i18n.language,
            '',
            BRAND_INFO,
          )}
          description={truncatedDescription}
          image={imageUrl?.url}
        />
      )}

      <div ref={mainDiv} className="main-content">
        <div
          // eslint-disable-next-line react/no-unknown-property
          onLoad={() => setShowFooter(true)}
          className="ContentOverviewLiveSection"
        >
          {selectedContent?.live &&
          selectedContent?.contentType === TYPES.SPORT ? (
            <ContentOverviewFuture
              content={selectedContent}
              isLoading={!selectedContent}
              onClickBackButton={goBackFunction}
              onClickPlayerButton={onClickPlayerButton}
              translationTexts={translationTexts}
              showPlayIcon={false}
              backgroundImage={'/images/bg_ficha_sport.jpg'}
              isModal={false}
              placementTooltip="right"
              showRating={!!showContentDescription}
              showChannel={!!showContentDescription}
              showYear={!!showContentDescription}
              showDuration={!!showContentDescription}
              showLive={false}
              showScheduleButton={false}
            >
              <>
                <GenericModal
                  title={translationTexts.share_on}
                  // eslint-disable-next-line react/no-children-prop
                  children={<SocialSharingList />}
                  isModal={false}
                  placement="right"
                />
                {!!endDateContent && endDateContent > new Date() && (
                  <SchedulePopover
                    content={selectedContent}
                    schedule=""
                    buttonType="icon"
                    placement="right"
                  />
                )}
              </>
            </ContentOverviewFuture>
          ) : (
            <ContentOverviewLive
              translationTexts={translationTexts}
              content={selectedContent}
              onClickPlayerButton={onClickPlayerButton}
              onClickBackButton={goBackFunction}
              networks={networks}
              isLoading={!selectedContent}
              currentContentPosition={currentContentPosition}
              contentNoImageProps={{
                backgroundColorOne: NO_IMAGE_DEFAULT().backgroundColorOne,
                backgroundColorTwo: NO_IMAGE_DEFAULT().backgroundColorTwo,
              }}
              catchError={catchError}
              redirectContent={validateUrlRedirect(currentContentPosition)}
              showPlayIcon={false}
            />
          )}
        </div>
        {selectedContent && selectedContent.statsUrl && (
          <IFrameComponent source={`${selectedContent.statsUrl}`} />
        )}

        <div>
          {selectedContentRelated && (
            <ContentRelated
              content={selectedContentRelated?.result}
              translationTexts={translationTexts}
              imageMode={IMAGE_MODE.THUMB}
              onClickContentSelected={onClickContentSelected}
              isLoading={!selectedContentRelated}
              catchError={() => {}}
              textMode={selectedContent?.contentType}
            />
          )}
        </div>
        {renderFooter()}
      </div>
    </React.Fragment>
  );
}

export default SectionErrorBoundary(ContentOverViewSection);
